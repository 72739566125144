import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['results','food','foodlist','carbs','proteins','fats','kcal'];

	connect () {
		console.log('Connected!');
		this.resultsTarget.innerHTML = '';

	}
	found() {
		let val = JSON.parse(this.foodlistTarget.value);
		this.carbsTarget.value = val.carbs;
		this.proteinsTarget.value = val.proteins;
		this.fatsTarget.value = val.fats;
		this.kcalTarget.value = val.kcal;

		this.foodTarget.value = val.name;
	}
    search() {
		let val = this.foodTarget.value 
		this.resultsTarget.innerHTML = "Searching for " + val;
		fetch("/mobile/findfood/search?search=" + encodeURIComponent(val))
			.then(response => response.json())
			.then((data) => {
				if (data.length > 0) {
					let html = '';

					for (const ingredient of data) {
						let inf = {
							name: ingredient.name,
							kcal: ingredient.kcal,
							proteins: ingredient.proteins,
							carbs: ingredient.carbs,
							fats: ingredient.fats
						}
						let infval = JSON.stringify(inf)
						html += "<option value='" +infval + "'>" + ingredient.name + '</option>';
					}
					this.resultsTarget.innerHTML = data.length + " resultaten";
					this.foodlistTarget.innerHTML = html;
				} else {
					this.resultsTarget.innerHTML = "Niets gevonden";
				}
			});
	}

	handleResults () {
		const [data, status, xhr] = event.detail;
		this.resultsTarget.innerHTML = xhr.response;
	}
}
