import { Controller } from 'stimulus';
import Quagga from '@ericblade/quagga2';

export default class extends Controller {
      static targets = ["message","video","json","grid","image1","image2"]

    getJSON(url, callback) {
	var xhr = new XMLHttpRequest();
	xhr.open('GET', url, true);
	xhr.responseType = 'json';
	xhr.onload = function () {
		var status = xhr.status;
		if (status === 200) {
			callback(null, xhr.response);
		} else {
			callback(status, xhr.response);
		}
	};
	xhr.send();
};


    url(ean) {
        return "https://world.openfoodfacts.org/api/v0/product/" + ean + ".json"
    }
    connect() {
        this.messageTarget.innerHTML = '';
        let that = this;
        Quagga.init({
            inputStream: {
                name: 'Live',
                type: 'LiveStream',
                constraints: {
                    width: 1280,
                    height: 720,
                    facingMode: "user",
                },
                target: this.videoTarget
            },
            decoder: {
                readers: ['upc_e_reader', 'upc_reader', 'code_128_reader', 'ean_reader','code_39_reader'],
                debug: {
                    drawBoundingBox: true,
                    showFrequency: true,
                    drawScanline: true,
                    showPattern: true
                }
            },
            debug: true,
            locate: true,
        }, function (err) {
            if (err) {
                console.log(err);
                return;
            }
            console.log('start');
            console.log(that.element);
            console.log('Initialization finished. Ready to start');
            Quagga.start();

        });
Quagga.onDetected(function (result) {
	console.log(result);
	var code = result.codeResult.code;
    console.log(code);
    that.messageTarget.innerHTML = code;
    that.getJSON('https://world.openfoodfacts.org/api/v0/product/' + code + '.json', function (err, data) {
	if (err !== null) {
		alert('Something went wrong: ' + err);
    } else {
        Quagga.stop();
        that.videoTarget.classList.add("hidden");
        that.gridTarget.classList.remove("hidden");
        that.image1Target.innerHTML = '<img src="'+ data.product.image_url + '">';
        that.image2Target.innerHTML = '<img src="'+ data.product.image_nutrition_url + '">';
        console.log(data);
        that.jsonTarget.innerHTML = JSON.stringify(data, null, 4);

	}
    });


});
Quagga.onProcessed(function (result) {
	if (result !== null && result.length > 0) {
		console.log(result);
	}
});

    }
}