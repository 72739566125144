import { Controller } from "stimulus"

export default class extends Controller {
  static values = { product: String }
  connect() {
        var bla = document.getElementById("bla");
        //bla.innerHTML = JSON.stringify(webkit.messageHandlers); //"Hello from iossku_controller.js";
        webkit.messageHandlers.observer.postMessage({"action": "request_products"});
    }
  
  selectproduct(event ) {
      let prod = event.params["product"];
      webkit.messageHandlers.observer.postMessage({ "action": "buy_products", "product": prod });
  }
}
