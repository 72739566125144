import { Controller } from 'stimulus';

export default class extends Controller {
      static targets = ["form"]

    initialize() {
    }
    toggleform(event) {
        if (document.documentElement.hasAttribute("data-turbo-preview")) {
        } else {
            if (this.formTarget.classList.contains('hidden')) {
                this.formTarget.classList.remove('hidden');
            } else {
                this.formTarget.classList.add('hidden');
            }
        }
        event.preventDefault();
    }
    showform() {
        if (this.formTarget.classList.contains('hidden')) {
            this.formTarget.classList.remove("hidden");
        } else {
        }
    }

}