import { Controller } from "stimulus"

export default class extends Controller {
//export default class extends Controller {
	static targets = ['videoplayer', 'button', 'canvas', 'devices', 'takephoto','form','filefield','submit'];
	static values = { currentcam: Number };
	static oldstream = null;

  connect() {
	  super.connect();
	  if (this.hasVideoplayerTarget) {
		  this.init();
	  }
	  else
	  {
		  //console.log("no videoplayer target");
	  }
	
	}

  init() {
		const constraints = {
			video: {
				width: {
					min: 1280,
					ideal: 1920,
					max: 2560,
				},
				height: {
					min: 720,
					ideal: 1080,
					max: 1440
				},
	  			facingMode: { exact: 'environment' },
			},
		};

		let that = this;
		let videoplayer = this.videoplayerTarget
		let devtarget = this.devicesTarget
		// navigator.mediaDevices.enumerateDevices().then(function (devices) {
		// 	for (var i = 0; i < devices.length; i++) {
		// 		var device = devices[i];
		// 		if (device.kind === 'videoinput') {
		// 			var option = document.createElement('option');
		// 			option.value = device.deviceId;
		// 			option.text = device.label || 'camera ' + (i + 1);
		// 			devtarget.appendChild(option);
		// 		}
		// 	}
		// });

		navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
				that.oldstream = stream;
				videoplayer.srcObject = stream;
				navigator.mediaDevices.enumerateDevices().then(function (devices) {
					for (var i = 0; i < devices.length; i++) {
						var device = devices[i];
						if (device.kind === 'videoinput') {
							var option = document.createElement('option');
							option.value = device.deviceId;
							option.text = device.label || 'camera ' + (i + 1);
							devtarget.appendChild(option);
						}
					}
				});
		});
	  	// 	navigator.mediaDevices.enumerateDevices().then(function (devices) {
		// 	for (var i = 0; i < devices.length; i++) {
		// 		var device = devices[i];
		// 		if (device.kind === 'videoinput') {
		// 			var option = document.createElement('option');
		// 			option.value = device.deviceId;
		// 			option.text = device.label || 'camera ' + (i + 1);
		// 			devtarget.appendChild(option);
		// 		}
		// 	}
		// });

	}

	setCam(event) {
		let newDeviceId = event.target.value;
		let newconstraints = {
			video: {
					width: {
					min: 1280,
					ideal: 1920,
					max: 2560,
				},
					height: {
					min: 720,
					ideal: 1080,
					max: 1440
				},
				deviceId: { exact: newDeviceId }
			},
		};

		navigator.mediaDevices.getUserMedia(newconstraints).then((stream) => {
			if (this.oldstream)
			{
				this.oldstream.getVideoTracks().forEach((track) => {
					const deviceId = track.getConstraints().deviceId
					track.stop();
				});
			}
			let videoplayer = this.videoplayerTarget
			videoplayer.srcObject = stream;
			this.oldstream = stream;
		});
		
	}


	setCam2 () {
		// Set the cam to the value in currentcam.



		console.log("Set Cam");
		const videoTracks = this.videoplayerTarget.srcObject.getVideoTracks();
		console.log(videoTracks);
		const track = videoTracks[0];
		console.log(track)
		const trackSettings = track.getSettings();
		const newDeviceId = this.currentcamValue;
		console.log(newDeviceId);
		track.stop();
		track.applyConstraints({
			deviceId: { exact: newDeviceId },
		});
		console.log("A");
		console.log(track);
		if (track.readyState == "ended") {
			const mediaSource = new MediaSource();
			this.videoplayerTarget.srcObject = mediaSource;
			const videoTracks = mediaSource.getVideoTracks();
			console.log(videoTracks);
			const track = videoTracks[0];
			track.applyConstraints({
				deviceId: { exact: newDeviceId },
			});
			track.start();			
		}
		else {
			track.start();
		}
	}

	cycleCam () {
		// Cycle through the cams.
		let current = this.currentcamValue;
		// Cycle through the option values and set the next one as the current value.
		if (current == this.devicesTarget.options.length - 1) {
			this.currentcamValue = 0;
		} else {
			this.currentcamValue = current + 1;
		}
		// stop videoplayer and set the new cam.
		// this.videoplayerTarget.srcObject.getVideoTracks().forEach((track) => track.stop());

		this.setCam(this.currentcamValue);
	}

	takePhoto() {
		this.canvasTarget.classList.remove('hidden');
		const canvas = this.canvasTarget;
		const context = canvas.getContext('2d');
		context.drawImage(this.videoplayerTarget, 0, 0, canvas.width, canvas.height);
		this.videoplayerTarget.srcObject.getVideoTracks().forEach((track) => track.stop());
		// Make takephoto button visible by removing hidden class 
		this.takephotoTarget.classList.remove('hidden');
	}

	processPhoto () {
		console.log("Process Photo");
		// create a dataUrl from the canvas and send it to the server.
		const dataUrl = this.canvasTarget.toDataURL('image/jpeg');

		const form = this.formTarget;  //new FormData();
		const formData = new FormData(form);

		const csrfToken = document.head.querySelector("[name='csrf-token']");
		console.log(csrfToken);
		formData.append('image[dataurl]', dataUrl);
		formData.append('authenticity_token', csrfToken.content);
		console.log(csrfToken.content);
		fetch('/mobile/fooddiary/label_photo', {
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'X-CSRF-Token': csrfToken.content
			},
			credentials: 'same-origin',
	  		method: 'POST',
			body: formData,
		});
	}
//   finalizeToggleform(element) {
//     console.log(element)
//     console.log("after toggle form")
//     this.init();
//   }
	// finalizeReflex (element, reflex, noop, reflexId) {
	// 	console.log('entering finalizeReflex');
	// 	console.log(reflex);
	// 	console.log(element);
	// 	console.log(reflexId);
    // //init();
	// 	// const { reflex } = anchorElement.dataset
	// 	// if (reflex.match(/toggleform$/)) alert('Eating...')
	// 	// if (reflex.match(/defecate$/)) anchorElement.innerText = 'Pooping...'
	// }


}
