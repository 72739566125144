import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["results", "form", "taginput","allergyinput","dietinfoinput","categoryinput","specialwishinput" ]
  static values = { tag: String }
  timeout
  pendingRequest

  connect() {
    console.log("Connected!")
  }

  loading() {
    console.log("Loading...")

    this.pendingRequest = true
    setTimeout(() => {
      if(this.pendingRequest) {
        this.resultsTarget.innerHTML = "<div>Searching...</div>"
      }
    }, 500)
  }

  // TODO refactor these methods into one 
  
  tag(event) {
    console.log("tag!")
    event.target.classList.toggle("ring")
    event.target.classList.toggle("ring-green-400")
    event.target.classList.toggle("font-bold")
    var arra = ""    
    if (this.taginputTarget.value == "") {
      arra = []
    }
    else {
      arra = JSON.parse(this.taginputTarget.value)
    }
    if (arra.includes( event.target.attributes.myvalue.value)) {
      arra = arra.filter(e => e !==  event.target.attributes.myvalue.value)
    } else {
      arra.push( event.target.attributes.myvalue.value)
    }
    this.taginputTarget.value = JSON.stringify(arra)
  
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
  }

  allergy(event) {
    event.target.classList.toggle("ring")
    event.target.classList.toggle("ring-teal-400")
    event.target.classList.toggle("font-bold")
    var arrb = ""    
    if (this.allergyinputTarget.value == "") {
      arrb = []
    }
    else {
      arrb = JSON.parse(this.allergyinputTarget.value)
    }
    if (arrb.includes( event.target.attributes.myvalue.value)) {
      arrb = arrb.filter(e => e !==  event.target.attributes.myvalue.value)
    } else {
      arrb.push( event.target.attributes.myvalue.value)
    }
    this.allergyinputTarget.value = JSON.stringify(arrb)
  
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
  }

    dietinfo(event) {
    event.target.classList.toggle("ring")
    event.target.classList.toggle("ring-blue-400")
    event.target.classList.toggle("font-bold")
    var arrb = ""    
    if (this.dietinfoinputTarget.value == "") {
      arrb = []
    }
    else {
      arrb = JSON.parse(this.dietinfoinputTarget.value)
    }
    if (arrb.includes( event.target.attributes.myvalue.value)) {
      arrb = arrb.filter(e => e !==  event.target.attributes.myvalue.value)
    } else {
      arrb.push( event.target.attributes.myvalue.value)
    }
    this.dietinfoinputTarget.value = JSON.stringify(arrb)
  
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
  }

  category(event) {
    event.target.classList.toggle("ring")
    event.target.classList.toggle("ring-yellow-400")
    event.target.classList.toggle("font-bold")
    var arrb = ""    
    if (this.categoryinputTarget.value == "") {
      arrb = []
    }
    else {
      arrb = JSON.parse(this.categoryinputTarget.value)
    }
    if (arrb.includes( event.target.attributes.myvalue.value)) {
      arrb = arrb.filter(e => e !==  event.target.attributes.myvalue.value)
    } else {
      arrb.push( event.target.attributes.myvalue.value)
    }
    this.categoryinputTarget.value = JSON.stringify(arrb)
  
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
  } 

  specialwish(event) {
    event.target.classList.toggle("ring")
    event.target.classList.toggle("ring-red-400")
    event.target.classList.toggle("font-bold")
    var arrb = ""    
    if (this.specialwishinputTarget.value == "") {
      arrb = []
    }
    else {
      arrb = JSON.parse(this.specialwishinputTarget.value)
    }
    if (arrb.includes( event.target.attributes.myvalue.value)) {
      arrb = arrb.filter(e => e !==  event.target.attributes.myvalue.value)
    } else {
      arrb.push( event.target.attributes.myvalue.value)
    }
    this.specialwishinputTarget.value = JSON.stringify(arrb)
  
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
  }





  search() {
        console.log("dosearch!")

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, 200)
  }

  handleResults() {
    this.pendingRequest = false
    const [data, status, xhr] = event.detail
    this.resultsTarget.innerHTML = xhr.response
  }
}