/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

//import Rails from '@rails/ujs';

import '@hotwired/turbo-rails';

//import * as Turbo from '@hotwired/turbo';
import * as ActiveStorage from '@rails/activestorage';

import * as ActionCable from '@rails/actioncable';

require('./channels');
require('./src/notifications');
require('./src/cable');
require('typeface-inter');
require('jquery');
//import { cable } from "@hotwired/turbo-rails"
//import flatpickr from 'flatpickr';
//import 'flatpickr/dist/flatpickr.min.css';
//import 'alpinejs';
import ApexCharts from 'apexcharts';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
require('typeface-inter');
import videojs from 'video.js';
import 'controllers'


ActionCable.logger.enabled = true;
ActionCable.logger.log = function (message) {
  console.log(message);
};


ActiveStorage.start();

window.ApexCharts = ApexCharts;
//window.Turbo = Turbo;

const application = Application.start();
const context = require.context('controllers', true, /.js$/);
require.context('./images', true);
application.load(definitionsFromContext(context));

//Rails.start();


// require('@rails/activestorage').start()
// require('css/application.scss')
// require('../src/notifications')



// require('jquery')
// import flatpickr from 'flatpickr'
// import 'flatpickr/dist/flatpickr.min.css'
// import 'alpinejs'
// import ApexCharts from 'apexcharts'
//require('channels');
// require('../src/cable');



//const application = Application.start()
//const context = require.context('controllers', true, /.js$/)
//require.context('images', true)
//application.load(definitionsFromContext(context))



// function addEvent (element, evnt, funct) {
//   if (element != null && typeof element !== 'undefined') {
//     if (element.attachEvent) return element.attachEvent('on' + evnt, funct)
//     else return element.addEventListener(evnt, funct, false)
//   }
// }

window.onerror = function (msg, url, line) {
	window.webkit.messageHandlers.mylog.postMessage('JS: ERROR:' + msg + ' @' + url + ':' + line);
};



// window.addEventListener('load', function () {
//   var player = document.getElementById('videojs');
//   console.log(player);

// if (player !== null) {
//   //player.play();
//   let player = videojs('videojs');

// 	// let player = videojs('#videojs');
// }

  
  // addEvent(
  //   document.getElementById('copypaste_public_dns_key'),
  //   'click',
  //   function () {
  //     copypaste('public_dns_key')
  //   }
  // )
  // addEvent(
  //   document.getElementById('copypaste_private_key'),
  //   'click',
  //   function () {
  //     copypaste('private_key')
  //   }
  // )
//})

// let copypaste = function (textareaid) {
//   console.log(textareaid)
//   const textarea = document.getElementById(textareaid)
//   textarea.select()
//   textarea.setSelectionRange(0, 99999)
//   document.execCommand('copy')
//   window.showSuccess('Copied')
// }

document.addEventListener('turbo:load', () => {

  var player = document.getElementById('videojs');
  console.log(player);
  if (player !== null)
  {
    //player.play();
    let player = videojs('videojs');
  }
  // flatpickr('.flatpickr', {
  //   altInput: true,
  //   altFormat: 'F j, Y',
  //   dateFormat: 'Y-m-d'
  // })
})

