import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['output']
  static values = { email: String }
  connect() {
        //var bla = document.getElementById("bla");
        //bla.innerHTML = JSON.stringify(webkit.messageHandlers); //"Hello from iossku_controller.js";
        //webkit.messageHandlers.observer.postMessage({"action": "request_products"});
     // this.outputTarget.innerHTML = "test A"
    }
  
    startsprenfingercamera(event) {
     // this.outputTarget.innerHTML = "test B"
      let email = event.params["email"];
      webkit.messageHandlers.observer.postMessage({ "action": "spren_finger_camera", "email": email });
      
  }
}
